import { Link } from "gatsby";
import styled from "styled-components";
import { DiScala } from "react-icons/di";

export const Nav = styled.nav`
  background: ${({ active }) =>
    active
      ? "#fff"
      : "linear-gradient(to bottom, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0) 100%)"};
  height: 3.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100vw;
    left: 0;

  @media screen and (max-width: 960px) {
    background: ${({ click }) => (click ? "#fff" : "transparent")};
    transition: 0.8s all ease;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 3.5em;
  z-index: 1;
  width: 100%;
  max-width: 100%;
  background-color: #fff;
  padding: 0 5em;

  @media screen and (max-width: 960px) {
    padding: 0 !important;
  }
`;

export const NavLogo = styled(Link)`
  color: "#141414";
  justify-self: flex-start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  outline: none;
`;

export const NavIcon = styled(DiScala)`
  margin: 0 0.5rem 0 2rem;
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 960px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%)
    font-size: 1.8rem;
    cursor: pointer;
    z-index: 999;

    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1em;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;

  @media only screen and (min-width: 768px) {
  }

  @media screen and (max-width: 960px) {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: ${({ click }) => (click ? "%100" : "-1000px")};
    opacity: 1;
    transition: all 0.2s ease;
    background: #000;
    padding: 5em 2em;
    opacity: 0.9;
    overflow-y: hidden;
  }
`;

export const NavItem = styled.li`
  height: 2em;

  @media screen and (max-width: 960px) {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 1.25em 0;
  }
`;

export const NavLinks = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: "#0E3F8C";

  @media only screen and (min-width: 960px) {
    font-size: 0.65em;
  }

  @media and screen (max-width: 960px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    font-size: 1em !important;
    color: ${({ click }) => (click ? "#0E3F8C" : "#fff")};

    ${
      "" /* &:active {
      color: #fff;
      background-color: transparent;
      text-decoration: none;
    } */
    }
  }
`;
