import React from "react";
import PropTypes from "prop-types";
import Navbar from "../components/Navbar";
import { ParallaxProvider } from 'react-scroll-parallax';
import { ServiceProvider } from "../contexts/ServiceContext";

import "./layout.css";
import Footer from "./Footer";

const Layout = ({ children }) => {

  return (
    <>
      <ServiceProvider>
       <ParallaxProvider>
         <div className="main-container">
          <Navbar />
            <main>
            {children}
            </main>
          <Footer />
         </div>
        </ParallaxProvider>
      </ServiceProvider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
