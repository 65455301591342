import { StaticImage } from "gatsby-plugin-image";
import React, { useState, useEffect, useContext } from "react";
import { FaCaretDown, FaCaretUp, FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
} from "./NavbarElements";
import "./Navbar.scss";
import { BrowserView, MobileView } from 'react-device-detect';
import ServiceContext from "../../contexts/ServiceContext";

const Navbar = () => {
  const { selectService } = useContext(ServiceContext);

  const [click, setClick] = useState(false);
  const [show, setShow] = useState(true);
  const [scroll, setScroll] = useState(false);

  const handleClick = () => setClick(!click);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  useEffect(() => {
    changeNav();
    window.addEventListener("scroll", changeNav);
  }, []);

  return (
    <>
      <IconContext.Provider value={{ color: "#141414" }}>
        <Nav className="main-nav" active={scroll} click={click}>
          <NavbarContainer>
            <NavLogo to="/">
              <StaticImage
                src="../../images/star-logo.png"
                width={125}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="star logo"
              />
            </NavLogo>
            <MobileIcon onClick={handleClick}>
              {click ? <FaTimes style={{ color: `#fff` }} /> : <FaBars />}
            </MobileIcon>

            <NavMenu click={click}>
              <NavItem>
                <NavLinks to="/">Home</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="/history">History</NavLinks>
              </NavItem>

              <NavItem>
                <NavLinks to="/our-footprint">Our Footprint</NavLinks>
              </NavItem>

              {/* <NavItem>
                <NavLinks to="/our-services">Our Services</NavLinks>
              </NavItem> */}

              <BrowserView>
                <NavItem>
                  <NavLinks>
                    <div className="service-dd">
                      <button className="dropbtn">Our Services</button>
                      <div className="dropdown-content">
                        <NavLinks 
                          onClick={() => selectService("steve")} 
                          to="/our-services">Stevedoring & Arrastre
                        </NavLinks>
                        <NavLinks 
                          onClick={() => selectService("load")} 
                          to="/our-services">Loading
                        </NavLinks>
                        <NavLinks
                          onClick={() => selectService("store")} 
                          to="/our-services">Transit Storage
                        </NavLinks>
                        <NavLinks 
                          onClick={() => selectService("cargo")} 
                          to="/our-services">Cargo Delivery
                        </NavLinks>
                        <NavLinks 
                          onClick={() => selectService("terminal")} 
                          to="/our-services">Terminal Management
                        </NavLinks>
                        {/* <NavLinks 
                          onClick={() => selectService("port")} 
                          to="/our-services">Port Facility Development
                        </NavLinks> */}
                        <NavLinks 
                          onClick={() => selectService("it")} 
                          to="/our-services">IT Services
                        </NavLinks>
                      </div>
                    </div>
                  </NavLinks>
                </NavItem>
              </BrowserView>

              <MobileView className="os-mob">
                <NavItem onClick={() => setShow(!show)}>
                  <NavLinks><span className="os-text">Our Services</span> { show ? <FaCaretUp className="caret-down" /> : <FaCaretDown className="caret-down" /> }</NavLinks>
                </NavItem>
              </MobileView>

              {show ?
                <MobileView className="m-services">
                  <NavItem>
                    <NavLinks to="/stevedoring-and-arrastre">Stevedoring & Arrastre</NavLinks>
                  </NavItem>
                  <NavItem>
                    <NavLinks to="/loading">Loading</NavLinks>
                  </NavItem>
                  <NavItem>
                    <NavLinks to="/transit-storage">Transit Storage</NavLinks>
                  </NavItem>
                  <NavItem>
                    <NavLinks to="/cargo-delivery">Cargo Delivery</NavLinks>
                  </NavItem>
                  <NavItem>
                    <NavLinks to="/terminal-management">Terminal Management</NavLinks>
                  </NavItem>
                  {/* <NavItem>
                    <NavLinks to="/our-services">Port Facility Development</NavLinks>
                  </NavItem> */}
                  <NavItem>
                    <NavLinks to="/it-services">IT Services</NavLinks>
                  </NavItem>
                </MobileView> : ""

              }

              <NavItem>
                <NavLinks to="/our-networks">Our Network</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks target="_blank" to="https://portal.aesiph.io/login">Login to Apollo</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="/contact">Contact</NavLinks>
              </NavItem>
              {/* <NavItem>
                <NavLinks to="/careers">Careers</NavLinks>
              </NavItem> */}
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
